import React, { useEffect, useState } from "react";
import { useGetOne, useGetList, useRefresh, useRedirect } from "react-admin";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import vtsDataProvider, { getData } from "./../providers/dataProvider";
import FormTextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import SendNotification from "../utils/sendNotification";
import { Tabs, Tab, Card } from "@mui/material";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import "../views/group/group.css";
import moment from "moment";
import { TabPanel } from "./../utils/TabPanel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TitleContext } from "../utils/TitleContext";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

let date = new Date();
const defaultValues = {
  attributes: {},
  groupId: 0,
  name: "",
  uniqueId: "",
  phone: "",
  vehicleModel: "",
  category: "",
  disabled: false,
  vltdmodel: "",
  serialNo: "",
  createdon: moment().format(),
  simno1: "",
  network1: "",
  sim1_expiry: "",
  simno2: "",
  network2: "",
  sim2_expiry: "",
  vstate: "",
  vehicleMake: "",
  permit_holder: "",
  altContactPerson: "",
  altPhone: "",
  categoryId: 0,
  constituencyName: "",
  sectorNumber: '',
  magistrateName: ''
};

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

export const AddEntity = ({
  currentEntity,
  handleEntityClose,
  listContext,
  setCurrentEntity
}) => {
  const [Entityvalue, setEntityValue] = useState(0);
  const [formValues, setFormValues] = useState(defaultValues);
  const [formError, setFormError] = useState({});
  const [notifyData, setNotifyData] = useState({});
  const [vltdno, setvltdno] = useState("");
  const [StateValue, setStateValue] = useState(null);
  const [makedrp, setMake] = useState([]);
  const [MakeValue, setMakeValue] = useState(null);
  const [rtoCodedrp, setRtoCodedrp] = useState([]);
  const [RtoValue, setRtoValue] = useState(null);
  const [simproviderdrp, setSimprovider] = useState([]);
  const [simprovidValue, setSimprovidValue] = useState(null);
  const [vehiclecategoryValue, setVehiclecategoryValue] = useState(null);
  const [newtab, setNewTab] = useState(0);
  const [notiopen, setNotiOpen] = useState(false);
  const [simData, setSimData] = useState({
    simCompany: "",
    iccId: "",
    uniqueId: "",
  });
  const [SimDetails, setOtpResponse] = useState(null);
  const [ErrorMsg, setErrorMsg] = useState("");
  const [vahandisabled, setvahandisabled] = useState(false);
  const state = { button: 0 };
  const [isSubmit, setIsSubmit] = useState(false);
  const [err, setErr] = useState("");
  const [selectRTOCode, setSelectRTOCode] = useState(null)


  const [stateDrpData, setStateDrpData] = useState([]);
  const [constituencyDrpData, setConstituencyDrpData] = useState([]);
  const [vehicleCategorydrp, setVehicleCategory] = useState([]);

  const [selectedStateValue, setSelectedStateValue] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedConstituencyValue, setSelectedConstituencyValue] = useState(null);
  const [selectedVehicleCategoryValue, setSelectedVehicleCategoryValue] = useState(null);
  const [entityObj, setEntityObj] = useState({});
  const [openEntity, setOpenEntity] = useState(true);

  const location = useLocation();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const redirect = useRedirect();

  const { data: stateList } = useGetList("state");
  const { data: vmakeList } = useGetList("vehiclemake");
  const { data: rtocodeList } = useGetList("rtocode");
  const { data: simprovidList } = useGetList("simprovider");
  const { data: vehiclecategoryList } = useGetList("vehiclecategory");
  const { data: constituencyList } = useGetList("constituency");
  const vehicleCategoryData = [{
    label: "Polling",
    value: "1",
    showlabel: "Polling"
  },
  {
    label: "Sector Officer",
    value: "2",
    showlabel: "Sector Officer"
  },
  {
    label: "Others",
    value: "3",
    showlabel: "Others"
  },
  {
    label: "Sector Reserved",
    value: "4",
    showlabel: "Sector Reserved"
  },
  {
    label: "Polling Reserved",
    value: "5",
    showlabel: "Polling Reserved"
  },]

  var today = new Date();
  today = new Date(today.setDate(today.getDate())).toISOString().split("T")[0];

  const { setTitle } = React.useContext(TitleContext);
  useEffect(() => {
    if (currentEntity) {
      if (currentEntity.id > 0) {
        setFormValues(currentEntity);

        var Statevalue = stateDrpData.filter(function (item) {
          return item.showlabel === currentEntity.vstate;
        });

        if (Statevalue) setStateValue(Statevalue[0]);

        var Makevalue = makedrp.filter(function (item) {
          return item.showlabel === currentEntity.vehicleMake;
        });

        if (Makevalue) setMakeValue(Makevalue[0]);

        var rtovalue = rtoCodedrp.filter(function (item) {
          return item.value === currentEntity.rto_code;
        });

        if (rtovalue) setRtoValue(rtovalue[0]);

        var categoryvalue = vehicleCategorydrp.filter(function (item) {
          return item.showlabel === currentEntity.category;
        });

        if (categoryvalue) setVehiclecategoryValue(categoryvalue[0]);

        var simproviName = simproviderdrp.filter(function (item) {
          return item.showlabel === currentEntity.simProviderName;
        });

        if (simproviName) setSimprovidValue(simproviName[0]);

        setvahandisabled(true);
      } else {
        setFormValues(defaultValues);
        setvahandisabled(false);
      }
    }
  }, [currentEntity]);

  useEffect(() => {
    if (vmakeList) {
      const list = formatdropdown(vmakeList);
      setMake(list);
    }
  }, [vmakeList]);

  useEffect(() => {
    if (rtocodeList) {
      const list = formatrtodropdown(rtocodeList);
      setRtoCodedrp(list);
    }
  }, [rtocodeList]);

  useEffect(() => {
    if (simprovidList) {
      const list = formatdropdown(simprovidList);
      setSimprovider(list);
    }
  }, [simprovidList]);

  useEffect(() => {
    if (stateList) {
      const list = formatdropdown(stateList);
      setStateDrpData(list);
    }
  }, [stateList]);

  useEffect(() => {
    if (constituencyList) {
      const constituency = constituencyList ? Object.values(constituencyList[0]).map(option => option.name).filter(name => name !== undefined) : [];
      const list = constituency?.map(name => ({ value: name, label: name }))
      setConstituencyDrpData(list);
    }
  }, [constituencyList]);

  useEffect(() => {
    if (vehiclecategoryList) {
      const list = formatdropdown(vehiclecategoryList);
      setVehicleCategory(list);
      if (formValues?.id && vehiclecategoryList?.length) {
        if (formValues?.category == '' || formValues?.category == null) {
          setSelectedCategoryValue(null)
        }
        else {
          const sel = vehiclecategoryList?.find(ob => ob?.name == formValues?.category)
          if(sel){
          setSelectedCategoryValue({
            label: (sel?.id)?.toString() + " - " + (sel?.name)?.toString(),
            value: sel?.id,
            showlabel: sel?.name,
          })}else setSelectedCategoryValue(null)
        }
      }
    }
  }, [formValues?.id, vehiclecategoryList]);

  useEffect(() => {
    if (!formValues?.id) return
    if (formValues?.id && stateList?.length) {
      if (formValues?.vstate == '' || formValues?.vstate == null) {
        setSelectedStateValue(null)
      }
      else {
        const sel = stateList?.find(ob => ob?.name == formValues?.vstate)
        if(sel){
        setSelectedStateValue({
          label: (sel?.id)?.toString() + " - " + (sel?.name)?.toString(),
          value: sel?.id,
          showlabel: sel?.name,
        })}
        else setSelectedStateValue(null)
      }
    }
  }, [formValues?.id, stateList]);

  useEffect(() => {
    if (!formValues?.id) return
    if (formValues?.id && constituencyList?.length > 0) {
      if (formValues?.constituencyName == '' || formValues?.constituencyName == null) {
        setSelectedConstituencyValue(null)
      }
      else {
        const sel = constituencyDrpData.find(ob => ob?.label == formValues?.constituencyName)
        if(sel){
        setSelectedConstituencyValue({
          label: sel?.label,
          value: sel?.label,
        })} else setSelectedConstituencyValue(null)
      }
    }
  }, [formValues?.id, constituencyDrpData]);

  useEffect(() => {
    if (!formValues?.id) return
    if (formValues?.id && rtoCodedrp?.length > 0) {
      if (formValues?.district == '' || formValues?.district == null) {
        setSelectRTOCode(null)
      }
      else {
        const sel = rtoCodedrp.find(ob => ob?.label == formValues?.district)
        setSelectRTOCode({
          label: sel?.label,
          value: sel?.label,
        })
      }
    }
  }, [formValues?.id, rtoCodedrp]);

  useEffect(() => {
    if (!formValues?.id) return
    if (formValues?.id && vehicleCategoryData?.length) {
      if (formValues?.vehicleType == '' || formValues?.vehicleType == null) {
        setSelectedVehicleCategoryValue(null)
      }
      else {
        const sel = vehicleCategoryData?.find(ob => ob?.label == formValues?.vehicleType)
        setSelectedVehicleCategoryValue({
          label: sel?.label,
          value: sel?.id,
          showlabel: sel?.label
        })
      }
    }
  }, [formValues?.id]);

  useEffect(() => {
    if (openEntity === true) {
      setEntityValue(0);
      setNewTab(0);
    }
  }, [openEntity]);

  useEffect(() => {
    if (location?.state != null) {
      setEntityObj(location?.state)
    }
    setTitle(location?.state != null ? "Edit Entity" : "Add Entity")
  }, [])

  useEffect(() => {
    if (entityObj) {
      const dt_sim1 = entityObj?.sim1_expiry?.substring(0, entityObj?.sim1_expiry.indexOf('T'));
      const dt_sim2 = entityObj?.sim2_expiry?.substring(0, entityObj?.sim2_expiry.indexOf('T'));
      setFormValues({
        ...entityObj,
        sim1_expiry: dt_sim1,
        sim2_expiry: dt_sim2
      })
    }
  }, [entityObj])


  const formatrtodropdown = (list) => {
    var te = list?.map((data, index) => ({
      label: (data?.name).toString(),
      value: data?.name,
      showlabel: (data?.name).toString(),
    }));
    return te;
  };

  const formatdropdown = (list) => {
    var te = list?.map((data, index) => ({
      label: (data?.id)?.toString() + " - " + (data?.name)?.toString(),
      value: data?.id,
      showlabel: data?.name,
    }));
    return te;
  };

  const yeardropdown = () => {
    const yeararr = [];
    const thisYear = new Date().getFullYear();

    for (let i = 0; i <= 50; i++) {
      const year = thisYear - i;
      yeararr.push(year);
    }

    var te = yeararr?.map((data, index) => ({
      label: data.toString(),
      value: data,
    }));
    return te;
  };

  const checknull = (pvalue) => {
    if (pvalue === "" || pvalue === undefined || pvalue === null) return ""
    return pvalue
  }

  const currentdate = moment().format();
  useEffect(() => {
    const getSimData = async () => {
      if (simData.iccId !== "" && simData.iccId !== undefined) {
        const responsedata = await getData("simdata", simData, setOtpResponse);
      }
    };
    getSimData();
  }, [simData]);

  useEffect(() => {
    const getSimInfo = () => {
      if (SimDetails) {
        if (SimDetails?.Error) {
          setErrorMsg(SimDetails?.Error);
        }
        else {
          setFormValues({
            ...formValues,
            simno1: SimDetails?.simno1,
            network1: SimDetails?.network1,
            sim1_expiry: SimDetails?.sim1_expiry
              ? moment(SimDetails.sim1_expiry).format("YYYY-MM-DD")
              : null,
            simno2: SimDetails?.simno2,
            network2: SimDetails?.network2,
            sim2_expiry: SimDetails?.sim2_expiry
              ? moment(SimDetails.sim2_expiry).format("YYYY-MM-DD")
              : null,
          });


          setErrorMsg("");
        }
        setSimData("");
      }
    };
    getSimInfo();
  }, [SimDetails]);


  const style = {
    bgcolor: "background.paper",
    border: "2px solid #000",
    p: 4,
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name == "simno1" || name == "simno2" || name == "phone" || name == "altPhone" || name== "sectorNumber") {
      const regex = /^[0-9\b]+$/;
      if (value == "" || regex.test(value)) {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    }
    // else if (name == "sectorNumber") {
    //   const reg = /^[A-Za-z0-9]*$/
    //   if (value == "" || reg.test(value)) {
    //     setFormValues({
    //       ...formValues,
    //       [name]: value,
    //     });
    //   }
    // }
    else if (name == "permit_holder" || name == "altContactPerson" || name === "magistrateName") {
      const reg = /^[a-zA-Z ]*$/
      if (value == "" || reg.test(value)) {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    }
    else if (name == "uniqueId") {
      const regex = /^[0-9\b]+$/;
      if (value == "" || regex.test(value)) {
        setFormValues({
          ...formValues,
          [name]: value,
          serialNo: value
        })
      }
    }
    else {
      if (name === "disabled")
        setFormValues({
          ...formValues,
          [name]: e.target.checked,
        })
      else
        setFormValues({
          ...formValues,
          [name]: value,
        });
    }
  };

  const clearValues = (params) => {
    if (params == "constituency") {
      setFormValues(prev => ({
        ...prev,
        constituencyName: ""
      }))
      setSelectedConstituencyValue(null)
    }
    else if (params == "state") {
      setFormValues(prev => ({
        ...prev,
        vstate: ""
      }))
      setSelectedStateValue(null)
    }
    else if (params == "category") {
      setFormValues(prev => ({
        ...prev,
        category: "",
        categoryId: 0
      }))
      setSelectedCategoryValue(null)
    }
    else if (params == "vehiclecategory") {
      setFormValues(prev => ({
        ...prev,
        vehicleType: ""
      }))
      setSelectedVehicleCategoryValue(null)
    }
    else if (params == "rtocode") {
      setFormValues(prev => ({
        ...prev,
        district: ""
      }))
      setSelectRTOCode(null)
    }
  }

  const handleSelectConstituency = (event, newValue) => {
    if (newValue != null) {
      setSelectedConstituencyValue(newValue)
      setFormValues((prevValues) => ({
        ...prevValues,
        constituencyName: newValue?.label
      }));
    }
    else if (newValue == null) clearValues("constituency")
  };

  const handleSelectState = (event, newValue) => {
    if (newValue != null) {
      setSelectedStateValue(newValue)
      setFormValues((prevValues) => ({
        ...prevValues,
        vstate: newValue?.showlabel
      }));
    }
    else if (newValue == null) clearValues("state")
  };

  const handleSelectCategory = (event, newValue) => {
    if (newValue != null) {
      setSelectedCategoryValue(newValue);
      setFormValues((prevValues) => ({
        ...prevValues,
        category: newValue?.showlabel,
        categoryId: newValue?.value
      }));
    }
    else if (newValue == null) clearValues("category")
  };

  const handleSelectVehicleCategory = (event, newValue) => {
    if (newValue != null) {
      setSelectedVehicleCategoryValue(newValue)
      setFormValues((prevValues) => ({
        ...prevValues,
        vehicleType: newValue?.showlabel
      }));
    }
    else if (newValue == null) clearValues("vehiclecategory")
  };

  const handleSelectRTOCode = (event, newValue) => {
    if (newValue != null) {
      setSelectRTOCode(newValue)
      setFormValues((prevValues) => ({
        ...prevValues,
        district: newValue?.label
      }));
    }
    else if (newValue == null) clearValues("rtocode")
  };

  const validate = (value) => {
    const errors = {};
    const reg = /^[a-zA-Z0-9]{19}$/;
    const phoneRegex = /^[0-9]{1,13}$/;

    if (checknull(formValues.phone) !== "") {
      if (formValues?.phone?.length < 10 || formValues?.phone?.length > 10) {
        errors.phone = "Enter valid phone number"
      }
    }
    if (checknull(formValues.altPhone) !== "") {
      if (formValues?.altPhone?.length < 10 || formValues?.altPhone?.length > 10) {
        errors.altPhone = "Enter valid phone number"
      }
    }
    if (checknull(formValues?.uniqueId) == "") {
      errors.uniqueId = "IMEI is required!";
    }
    if (checknull(formValues.simno1) !== "") {
      if (formValues?.simno1?.length < 10 || formValues?.simno1?.length > 15) {
        errors.simno1 = "Enter valid phone number"
      }
    }
    if (checknull(formValues.simno2) !== "") {
      if (formValues?.simno2?.length < 10 || formValues?.simno2?.length > 15) {
        errors.simno2 = "Enter valid phone number"
      }
    }
    if (checknull(formValues.sectorNumber) !== "") {
      if (formValues?.sectorNumber?.length < 10 || formValues?.sectorNumber?.length > 10) {
        errors.sectorNumber = "Enter valid phone number"
      }
    }
    if (checknull(formValues?.name) == "") {
      errors.name = "Registration Name is required!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      if (!formValues?.id) {
        vtsDataProvider.create(
          "addentity",
          formValues,
          setNotifyData,
          "Entity Created Successfully",
          "Entity Creation Failed."
        )
      } else {
        vtsDataProvider.update(
          "addentity",
          formValues,
          setNotifyData,
          "Entity Updated Successfully",
          "Failed to Update Entity"
        );
      }
    } else {
      setIsSubmit(false);
    }
  }, [formError])

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  }

  useEffect(() => {
    if (checknull(notifyData?.severity) !== "") {
      if (checknull(notifyData.backendmessage) === "") {
        setFormValues(defaultValues);
      }
      if (notifyData?.severity === "success") {
        setTimeout(navigate('/groups'), 6000);
      }
    }
    else {
      setNotiOpen(false);
    }
  }, [notifyData]);


  useEffect(() => {
    setFormValues({ ...formValues, vstate: StateValue?.showlabel });
  }, [StateValue]);

  useEffect(() => {
    setFormValues({ ...formValues, vehicleMake: MakeValue?.showlabel });
  }, [MakeValue]);

  useEffect(() => {
    setFormValues({ ...formValues, rto_code: RtoValue?.value });
  }, [RtoValue]);

  useEffect(() => {
    setFormValues({ ...formValues, category: vehiclecategoryValue?.showlabel });
  }, [vehiclecategoryValue]);

  // useEffect(() => {
  //   setFormValues({ ...formValues, manufacture_year: yearValue?.value });
  // }, [yearValue]);  

  useEffect(() => {
    setFormValues({ ...formValues, simProviderName: simprovidValue?.showlabel });
  }, [simprovidValue]);

  const handleClose = () => {
    setFormValues(defaultValues);
    setFormError({});
    setIsSubmit(false);
    // setYearValue(null);
    setRtoValue(null);
    setStateValue(null);
    setMakeValue(null);
    setVehiclecategoryValue(null);
    setErrorMsg("");
    setErr("")
    setNotiOpen(false);
    setOpenEntity(false)
    redirect('/groups');
  };

  const EntityBackTabChange = (newValue) => {
    setEntityValue(newValue);
  };


  return (
    <>
      <Box
        open={openEntity}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="new-entitity AddRoleModal"
      >
        <Box sx={style} className="modal-box">
          <form onSubmit={handleSubmit}>
            <Box className="right-side">
              <Box sx={{ flexGrow: 1, marginTop: "4em" }} className="div-box">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" component="h2" className="sub-title" sx={{ marginTop: "2em" }}>
                        Vehicle Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Box sx={{ marginTop: "2em" }}>
                            <FormControl>
                              <FormLabel>Registration #</FormLabel>
                              <FormTextField
                                name="name"
                                placeholder="Enter"
                                type="text"
                                value={formValues?.name}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                          </Box>
                          <div style={errorstyle}>
                            {formError?.name}
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ marginTop: "2em" }}>
                            <FormControl sx={{ width: "94%" }}>
                              <FormLabel>State</FormLabel>
                              <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                disablePortal
                                id="vstate"
                                name="vstate"
                                className="select-auto"
                                options={stateDrpData}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.value}>
                                    {option.showlabel}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                                getOptionLabel={(option) => option.showlabel}
                                onChange={(event, newValue) => handleSelectState(event, newValue)}
                                value={selectedStateValue}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.state}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ marginTop: "2em" }}>
                            <FormControl>
                              <FormLabel>Permit Holder</FormLabel>
                              <FormTextField
                                name="permit_holder"
                                placeholder="Enter"
                                type="text"
                                value={formValues?.permit_holder}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.permitHolder}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            <FormControl>
                              <FormLabel>Contact Person</FormLabel>
                              <FormTextField
                                name="altContactPerson"
                                placeholder="Enter"
                                type="text"
                                value={formValues?.altContactPerson}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.altContactPerson}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box>
                            <FormControl>
                              <FormLabel>Phone1</FormLabel>
                              <FormTextField
                                name="phone"
                                placeholder="Enter"
                                type="tel"
                                value={formValues?.phone}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 10 }}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.phone}</div>
                          </Box>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Box>
                              <FormControl sx={{ width: "94%" }}>
                                <FormLabel>Vehicle Type</FormLabel>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  disablePortal
                                  id="category"
                                  name="category"
                                  className="select-auto"
                                  options={vehicleCategorydrp}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                      {option.showlabel}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.showlabel}
                                  onChange={(event, newValue) => {
                                    setSelectedCategoryValue(newValue);
                                    setFormValues((prevValues) => ({
                                      ...prevValues,
                                      category: newValue?.showlabel
                                    }));
                                  }}
                                  value={selectedCategoryValue}
                                />
                              </FormControl>
                            </Box>
                          </Grid> */}
                        <Grid item xs={4}>
                          <Box>
                            <FormControl sx={{ width: "94%" }}>
                              <FormLabel>Phone2</FormLabel>
                              <FormTextField
                                name="altPhone"
                                placeholder="Enter"
                                type="tel"
                                value={formValues?.altPhone}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 10 }}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.altPhone}</div>
                          </Box>
                        </Grid>

                        {/* <Grid item xs={4}>
                          <Box>
                            <FormControl sx={{ width: "94%" }}>
                              <FormLabel>Vehicle Type</FormLabel>
                              <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                disablePortal
                                id="vehicleType"
                                name="vehicleType"
                                className="select-auto"
                                options={vehicleCategoryData}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.value}>
                                    {option.showlabel}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                                getOptionLabel={(option) => option.showlabel}
                                onChange={(event, newValue) => handleSelectVehicleCategory(event, newValue)}
                                value={selectedVehicleCategoryValue}
                              />
                            </FormControl>
                          </Box>
                        </Grid> */}
                        <Grid item xs={4}>
                          <Box>
                            <FormControl>
                              <FormLabel>RTO Code</FormLabel>
                              <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                disablePortal
                                id="rtoCode"
                                name="rtoCode"
                                className="select-auto"
                                options={rtoCodedrp}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.value}>
                                    {option.label}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => handleSelectRTOCode(event, newValue)}
                                value={selectRTOCode}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.magistrateName}</div>
                          </Box>
                        </Grid>
                        {/* <Grid item xs={4}>
                          <Box>
                            <FormControl sx={{ width: "94%" }}>
                              <FormLabel>Constituency Name</FormLabel>
                              <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                  option.label === value.label
                                }
                                disablePortal
                                id="constituencyName"
                                name="constituencyName"
                                className="select-auto"
                                options={constituencyDrpData}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.value}>
                                    {option.label}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => handleSelectConstituency(event, newValue)}
                                value={selectedConstituencyValue}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.constituencyName}</div>
                          </Box>
                        </Grid> */}



                        {/* <Grid item xs={4}>
                          <Box>
                            <FormControl>
                              <FormLabel>vahan prabhari contact</FormLabel>
                              <FormTextField
                                name="sectorNumber"
                                placeholder="Enter"
                                type="tel"
                                value={formValues?.sectorNumber}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 10 }}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.sectorNumber}</div>
                          </Box>
                        </Grid> */}
                        {/* <Grid item xs={4}>
                          <Box>
                            <FormControl>
                              <FormLabel>vahan prabhari</FormLabel>
                              <FormTextField
                                name="magistrateName"
                                placeholder="Enter"
                                type="text"
                                value={formValues?.magistrateName}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                            <div style={errorstyle}>{formError?.magistrateName}</div>
                          </Box>
                        </Grid> */}
                        <Grid item xs={4}>
                          <Box>
                            <div style={{ marginTop: "0.5em" }}>
                              <input
                                type="checkbox"
                                name="disabled"
                                checked={formValues?.disabled}
                                onChange={handleInputChange}
                              />
                              <label style={{ marginLeft: "10px" }}>Device Disabled</label>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        borderTop: "1.2px solid #eee",
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" component="h2" className="sub-title" sx={{ marginTop: "1em" }}>
                          Device Details
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Box sx={{ marginTop: "2em" }}>
                              <FormControl sx={{ width: "94%" }}>
                                <FormLabel>Category</FormLabel>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  disablePortal
                                  id="category"
                                  name="category"
                                  className="select-auto"
                                  options={vehicleCategorydrp}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                      {option.showlabel}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.showlabel}
                                  onChange={(event, newValue) => handleSelectCategory(event, newValue)}
                                  value={selectedCategoryValue}
                                />
                              </FormControl>
                              <div style={errorstyle}>{formError?.category}</div>
                            </Box>
                          </Grid>
                          <Grid item xs={8}>
                            <Box sx={{ marginTop: "2em" }}>
                              <FormControl>
                                <FormLabel>IMEI</FormLabel>
                                <FormTextField
                                  name="uniqueId"
                                  placeholder="Enter"
                                  type="text"
                                  value={formValues.uniqueId}
                                  inputProps={{ maxLength: 15 }}
                                  onChange={handleInputChange}
                                  disabled={formValues?.id}
                                />
                              </FormControl>
                              <div style={errorstyle}>{formError?.uniqueId}</div>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box>
                              <FormControl>
                                <FormLabel>SIM Number 1</FormLabel>
                                <FormTextField
                                  key="random1"
                                  name="simno1"
                                  placeholder="Enter sim no1"
                                  type="tel"
                                  value={formValues.simno1}
                                  onChange={handleInputChange}
                                  inputProps={{ maxLength: 15 }}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.simno1}</div>
                          </Grid>
                          <Grid item xs={4}>
                            <Box>
                              <FormControl>
                                <FormLabel>Network 1</FormLabel>
                                <FormTextField
                                  name="network1"
                                  placeholder="Enter network1"
                                  type="text"
                                  value={formValues.network1}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>
                              {formError?.network1}
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <Box>
                              <FormControl>
                                <FormLabel>Sim 1 Expiry</FormLabel>
                                <FormTextField
                                  name="sim1_expiry"
                                  placeholder="choose sim1 expiry"
                                  type="date"
                                  sx={{ width: "205px", height: "37px" }}
                                  // inputProps={{ min: today }}
                                  value={formValues.sim1_expiry}
                                  onChange={handleInputChange}

                                />
                              </FormControl>
                              <div style={errorstyle}>{formError?.sim1_expiry}</div>
                            </Box>

                          </Grid>
                          <Grid item xs={4}>
                            <Box>
                              <FormControl>
                                <FormLabel>SIM Number 2</FormLabel>
                                <FormTextField
                                  name="simno2"
                                  placeholder="Enter sim no2"
                                  type="tel"
                                  value={formValues.simno2}
                                  inputProps={{ maxLength: 15 }}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.simno2}</div>
                          </Grid>
                          <Grid item xs={4}>
                            <Box>
                              <FormControl>
                                <FormLabel>Network 2</FormLabel>
                                <FormTextField
                                  name="network2"
                                  placeholder="Enter network2"
                                  type="text"
                                  value={formValues.network2}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>
                              {formError?.network2}
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <Box>
                              <FormControl>
                                <FormLabel>Sim 2 Expiry</FormLabel>
                                <FormTextField
                                  name="sim2_expiry"
                                  placeholder="choose sim2 expiry"
                                  type="date"
                                  sx={{ width: "205px", height: "37px" }}
                                  // inputProps={{ min: today }}
                                  value={formValues.sim2_expiry}
                                  onChange={handleInputChange}

                                />
                              </FormControl>
                              <div style={errorstyle}>{formError?.sim2_expiry}</div>
                            </Box>

                          </Grid>
                          <Grid item xs={8} sx={{ marginTop: "2em" }}></Grid>
                          <Grid item xs={4} sx={{ marginTop: "2em" }}>
                            <div className="modal-footer">
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                {!formValues?.id ? "Save Entity" : "Update Entity"}
                              </Button>
                              <Button
                                variant="contained"
                                onClick={handleClose}
                                className="cancel-btn"
                                sx={{ marginRight: "1em", color: "#4444eb!important", boxShadow: "none !important" }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      {/* {notiopen === true ?
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} /> : ""} */}
      {<SendNotification data={notifyData} onClose={() => setNotifyData({})} />}
    </>
  );
};
