// import { useEffect } from "react";
// import L, { marker } from "leaflet";
// import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
// import "leaflet-routing-machine";
// import { useMap } from "react-leaflet";
// import RTaxiicon from '../../src/images/rc.png';
// import moment from 'moment';
import { useEffect, useState } from "react";
import { initialize, MMI_KEY } from './../utils/initializeMap'
import { useGetOne } from "react-admin";
import CloseIcon from '@mui/icons-material/Close';

// L.Marker.prototype.options.icon = L.icon({
//   iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"
// });

// let TaxiIcon = L.icon({
//   iconUrl: RTaxiicon,
//   iconSize: [25, 41],
// });

// let StartIcon = L.icon({
//   iconUrl: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
//   iconSize: [25, 41],
// });
// let EndIcon = L.icon({
//   iconUrl: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
//   iconSize: [25, 41],
// });

// let MidIcon = L.icon({
//   iconUrl: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
//   iconSize: [25, 41],
// });

export default function CreateRouteMap({ legDetails }) {
  const [planRoute, setPlanRoute] = useState([]);
  const [markerpoint, setMarkerPoint] = useState([]);
  const [mapObject, setMapObject] = useState(null);
  const [markerList, setmarkerList] = useState([]);
  const [layerstyle, setLayerStyle] = useState("standard-day");
  const styleMap = { width: '100%', height: '69vh', display: 'inline-block' }
  var mapObj;
  var polyline;

  useEffect(() => {
    initialize(MMI_KEY, () => {
      //Action after script has been loaded completely
      afterScriptsLoaded();
    })
  }, [])

  // useEffect(() => {

  //   if (planRoute) {
  //     if (planRoute.length > 0) {
  //       afterScriptsLoaded();
  //     }
  //   }
  //   else{
  //     if(mapObject)
  //   }

  // }, [planRoute,layerstyle])

  function afterScriptsLoaded() {
    mapObj = window.mappls.Map('map', {
      center: [21.35516, 81.28199],
      zoom: 7,
      zoomControl: true,
    })
    setMapObject(mapObj)
  }

  useEffect(()=>{
    if(mapObject)
    {
      
    window.mappls.setStyle(layerstyle)

//mapObject.addListener('load', function () {
      if (planRoute) {
        if (planRoute.length > 0) {

          legDetails.map((points, index) => {

            if (markerList && markerList?.length>0) {
              markerList.map((mvmarker) => {
                window.mappls.remove({ map: mapObject, layer: mvmarker });
              })
            }
           
            if(index === 0)
            {
              const marker1 = window.mappls.Marker({
                map: mapObject,
                position: {lat:points.latitude, lng: points.longitude},
                icon_url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                fitbounds: true,
                popupHtml: "<div>Name : " + points.name + "</div><div>Address : " + points.address + "</div>"

              });
              setmarkerList([...markerList,marker1]);

              planRoute.unshift({lat:points.latitude?.toString(), lng: points.longitude?.toString()})
            }
            else if(index === (legDetails.length -1))
            {
              const marker2 = window.mappls.Marker({
                map: mapObject,
                position: {lat:points.latitude, lng: points.longitude},
                icon_url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                fitbounds: true,
                popupHtml: "<div>Name : " + points.name + "</div><div>Address : " + points.address + "</div>"

              });
              setmarkerList([...markerList,marker2]);
              planRoute.push({lat:points.latitude?.toString(), lng: points.longitude?.toString()})
            }
            else{
              const marker3 = window.mappls.Marker({
                map: mapObject,
                position: {lat:points.latitude, lng: points.longitude},
                icon_url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
                fitbounds: true,
                popupHtml: "<div>Name : " + points.name + "</div><div>Address : " + points.address + "</div>"

              });
              setmarkerList([...markerList,marker3]);
             
            }
          });

          polyline = window.mappls.Polyline({
            map: mapObject,
            paths: planRoute,
            strokeColor: 'green',
            strokeOpacity: 1.0,
            strokeWeight: 3,
            fitbounds: true
          });

        }
      }
   // });
    }
  },[mapObject,planRoute,layerstyle])

  useEffect(()=>{
    if (mapObject) {
    loadDataOnlyOnce();
    clickLayer()
    }
    },[mapObject])

    function clickLayer(){
      // if(mapObject){
        document.getElementById('map_default').onclick = function(e) {
          setLayers('standard-day')
        }
        document.getElementById('grey-day').onclick = function(e) {
          setLayers('grey-day')
        }
        document.getElementById('standard-night').onclick = function(e) {
          setLayers('standard-night')
        }
        document.getElementById('map_hybrid').onclick = function(e) {
          setLayers('standard-hybrid')
        }
        
      }

  const loadDataOnlyOnce = () => {
      
    //  mapObject.addListener('load', function() {
    //  var container = document.getElementById('mmi_ctrl1')
    var container = document.querySelector(".expand-map-control")
     var layer_id = document.getElementById('layerId')
     if(!layer_id)
        // container.innerHTML +='<div id="layerId"><button name="layerctrl" >Layer</button></div>'
        container.innerHTML +='<li id="myBtn" class="map-layers"><a style="height: 36px;line-height: 32px;" title=" Map Layers" nofollow="" > <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LayersIcon"><path d="m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path></svg></a>\
        <ul class="clearfix map-layer" id="map-layer" style="display:none">\
        <span class="" id="layer-close"> X </span>\
        <li id="map_default" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock active">\
              <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" />\
                  <img src="https://apis.mapmyindia.com/vector_map/thumb/style.jpg" alt="" class="mode-img" />\
               </span>\
               <p>Default Map </p>\
            </a>\
         </li><li id="grey-day" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock">\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/gray.jpg" alt="" class="mode-img" style={{width: "82px",height: "56px",right: 0,bottom: 0}} />\
            </span>\
            <p>Grey Mode </p>\
         </a>\
      </li><li id="standard-night" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock" >\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/nightmode.jpg" alt="" class="mode-img" />\
            </span>\
            <p>Night Mode </p>\
         </a></li>\
         <li id="map_hybrid" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock" >\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/maplayer/hy_map.png" alt="" class="mode-img" />\
            </span>\
            <p>Satellite </p>\
         </a></li>\
      </ul>\
        </li>'
 
      // };
    
};

  useEffect(() => {

    if (legDetails) {

      let pointsarr = [];
      let coordarray = "";
      let coordarray1 = [];
      legDetails.map((points, index) => {

        if (index === (legDetails.length - 1)) {
          const routepoints = points?.subRoutePoints?.legs;
          if (routepoints) {

            const routesCopy = routepoints?.filter(ob => ob?.polyline != '')

            routesCopy.map((route, index) => {
              let area = route.polyline;

              let startpoint = area.indexOf("(");
              let endpoint = area.indexOf(")");

              let latlngs = area.substring(startpoint + 1, endpoint).trim();
              //get lat and lng
              const latlngarray = latlngs.split(",");
             
              latlngarray.map((pos) => {
                const posarray = pos.split(" ");
                
                let latitude = posarray[0] === "" ? posarray[1] : posarray[0];
                let longitude = posarray[0] === "" ? posarray[2] : posarray[1];

                pointsarr.push({ lat: latitude, lng: longitude })
              })
            })
            setPlanRoute(pointsarr)
          }
          }
        });
    }
  }, [legDetails]);

  const setLayers = (id) => {
    setLayerStyle(id)
   // window.mappls.setStyle(id)
  }

  return (
    <div>
        
      <div id="map" style={styleMap}></div>

    </div>
  );
}
